import React from 'react';
import { initializeApollo } from '../lib/apollo-client';
import APP_QUERY from '../queries/App.graphql';
import CmsPage from '../components/CmsPage';
import getPageData from '../lib/getPageData';
//import getAppData from '~/lib/getAppData';

const HomePage = (props) => {
    const { pageData } = props;
    return <CmsPage pageData={pageData} />;
};

export const getStaticProps = async (props) => {
    const apolloClient = initializeApollo();
    let pageData = null;
    const { data: appData } = await apolloClient.query({
        query: APP_QUERY
    });
    const { res } = props;

    pageData = await getPageData({
        identifier: appData.storeConfig.cms_home_page,
        urlKey: appData.cms_home_page
    });

    return {
        props: {
            pageData
        },
        revalidate: Number(process.env.STATIC_PROPS_REVALIDATE)
    };
};

export default HomePage;
