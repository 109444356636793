import React from 'react';
import Head from 'next/head';
import Breadcrumbs from '../Breadcrumbs';
import RichContent from '../RichContent/RichContent';
import CmsBlock from '../CmsBlock';
export const CmsPage = (props) => {
    const { pageData } = props;
    //console.log('CMS Page API data: ', pageData);
    return (
        <React.Fragment>
            <Head>
                <title>{pageData?.meta_title}</title>
                <meta
                    name="description"
                    content={pageData?.meta_description}
                ></meta>
            </Head>
            <CmsBlock identifiers={['clothing_cateogory']} />
            <div className="w-full">
                <div>
                    {pageData.url_key != 'homepage-megastore' ? (
                        <div className="bg-skin-site-secondary-bg">
                            <div className="self-start">
                                <Breadcrumbs current={pageData?.title} />
                            </div>
                        </div>
                    ) : null}
                    <div className="w-full">
                        <RichContent html={pageData?.content} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
